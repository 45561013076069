import React from "react"
import { Button, Icon, Typography } from "antd"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import WRPSearch from "../WRPSearch/WRPSearch"
import ButtonModal from "../ButtonModal/ButtonModal"
import OptInModal from "../Forms/OptInModal"
import style from "./BookingHero.module.scss"

class BookingHero extends React.Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hotelID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modalImg: PropTypes.object.isRequired,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }

  static defaultProps = {
    alt: null,
    fluid: null,
    height: `70vh`,
    hotelID: process.env.WRP_HOTEL_ID,
    modalImg: null,
    phone: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      vis: false,
    }
  }

  toggleModal = () => {
    this.setState({
      vis: !this.state.vis,
    })
  }

  render() {
    const { height, modalImg, phone, hero } = this.props

    return (
      <div className={style.wrapper} style={{ height: height }}>
        <Img
          alt={hero.description}
          className={style.image}
          fluid={hero.fluid}
          imgStyle={{ height: `100%`, width: `100%`, objectFit: `cover` }}
        />
        <div className={style.searchWrapper}>
          <Typography.Title className={style.title} level={1}>
            Book Your Room Today
          </Typography.Title>
          <WRPSearch hotelID={this.props.hotelID} phone={phone} />
          <div className={style.buttonWrapper}>
            <div className={style.email}>
              <ButtonModal
                cta="Get Email Offers"
                image={modalImg}
                title="Be an insider."
                body={
                  <>
                    <Typography.Paragraph
                      style={{ fontSize: 18, marginBottom: 24 }}
                    >
                      Sign up to have exclusive savings and offers,{" "}
                      <strong>
                        including 24 hours of early access to reservations on
                        game day weekends
                      </strong>
                      , delivered right to your email inbox! Unsubscribe any
                      time!
                    </Typography.Paragraph>
                    <OptInModal
                      automationId={36}
                      formName="Email Offers"
                      gtmClickId="get-email-offers-form"
                      cancelText="Nevermind"
                      submitText="Sign Up!"
                      toggleModal={this.toggleModal}
                    />
                  </>
                }
                hideButtons={true}
                visible={this.state.vis}
                toggleModal={this.toggleModal}
              />
            </div>
            <div className={style.call}>
              <a href={`tel:${phone}`}>
                <Button className={style.call} size="large" type="primary">
                  <span>
                    <Icon type="phone" />
                    {phone}
                  </span>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        contact: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
          phone
        }
        hero: contentfulAsset(contentful_id: { eq: "4yvi1KUsiYfYMN9iSTeCcS" }) {
          description
          fluid(quality: 50) {
            ...fluid_withWebp
          }
        }
        modalImg: contentfulAsset(
          title: { eq: "Early Access - Game Day Tickets" }
        ) {
          description
          fluid(maxWidth: 520) {
            ...fluid_withWebp_tracedSVG
          }
        }
      }
    `}
    render={data => (
      <BookingHero
        hero={data.hero}
        height={props.height}
        modalImg={data.modalImg}
        phone={data.contact.phone}
      />
    )}
  />
)
