import { useEffect } from "react"

function useEmbedly({ target, ...rest }) {
  const { src, script } = Object.assign(
    {
      src: `/embedly.js`,
      script: null,
    },
    rest
  )

  return useEffect(() => {
    let embedlyJS, embedlyCSS, observer

    observer = new IntersectionObserver(entries => {
      entries.forEach(
        entry => {
          if (!entry.isIntersecting) return
          embedlyJS = document.createElement(`script`)

          if (!script) {
            embedlyJS.src = src
          } else {
            embedlyJS.textContent = script
          }

          document.body.appendChild(embedlyJS)

          embedlyCSS = document.createElement(`link`)
          embedlyCSS.rel = `stylesheet`
          embedlyCSS.className = `embedly-css`
          embedlyCSS.href = `/embedly.css`
          document.head.appendChild(embedlyCSS)
        },
        {
          root: window,
          rootMargin: `200px 0px 200px 0px`,
          threshold: 0.15,
        }
      )
    })

    observer.observe(target.current)

    return () => {
      observer.disconnect()
    }
  }, [src, script, target])
}

export default useEmbedly
