import * as React from "react"
import { Icon, Spin } from "antd"
import useEmbedly from "../../utils/useEmbedly"

// reference: https://docs.embed.ly/docs/cards#customize
function EmbededVideo({
  dataCardAlign = `center`,
  dataCardChrome = 0,
  dataCardControls = 0,
  dataCardTheme = `light`,
  dataCardWidth = `100%`,
  nominalResolution = [1920, 1080],
  oEmbedUrl,
  videoDescription = `Embedded Video`,
  target,
  ...rest
}) {
  const ref = React.useRef()
  const [height, setHeight] = React.useState(300)
  const [isSpinning, setIsSpinning] = React.useState(true)

  useEmbedly({ target: ref })

  React.useEffect(() => {
    const calcVideoHeight = () => {
      const [nominalWidth, nominalHeight] = nominalResolution
      const aspectRatio = nominalWidth / nominalHeight
      const height = ref.current.clientWidth / aspectRatio
      setHeight(height)
    }

    calcVideoHeight()

    window.addEventListener(`resize`, calcVideoHeight)

    const stopSpinning = setTimeout(() => {
      setIsSpinning(false)
    }, 5000)

    return () => {
      clearTimeout(stopSpinning)
      window.removeEventListener(`resize`, calcVideoHeight)
    }
  }, [nominalResolution])

  const iframeUrl = oEmbedUrl.replace(`embedType=async`, `embedType=iframe`)

  return (
    <div
      style={{
        alignItems: `center`,
        display: `flex`,
        height,
        justifyContent: `center`,
        position: `relative`,
        width: `100%`,
      }}
    >
      <Spin
        indicator={<Icon type="loading" />}
        size="large"
        spinning={isSpinning}
        tip={videoDescription}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <a
            class="embedly-card"
            data-card-align="${dataCardAlign}"
            data-card-chrome="${dataCardChrome}"
            data-card-controls="${dataCardControls}"
            data-card-theme="${dataCardTheme}"
            data-card-width="${dataCardWidth}"
            href="${iframeUrl}"
          ></a>
        `,
        }}
        ref={ref}
        style={{
          height,
          position: `absolute`,
          width: `100%`,
        }}
        {...rest}
      />
    </div>
  )
}

export default EmbededVideo
