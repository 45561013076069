import React from "react"
import { Button, Col, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import BookingHero from "../components/BookingHero/BookingHero"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import EmbededVideo from "../components/oEmbedVideo/oEmbedVideo"

const fullRow = {
  align: "middle",
  gutter: 24,
  justify: "center",
  type: "flex",
}

const bodyWidth = {
  xs: 22,
  md: 22,
  lg: 20,
  xl: 18,
}

const fullCol = {
  xs: bodyWidth.xs,
  md: bodyWidth.md,
  lg: 18,
  xl: 16,
}

const halfCol = {
  xs: bodyWidth.xs,
  md: bodyWidth.md / 2,
  lg: bodyWidth.lg / 2,
  xl: bodyWidth.xl / 2,
}

const halfColAlt = order => {
  return {
    xs: {
      order: (order % 2) + 1,
      span: 22,
    },
    md: {
      order: order,
      span: bodyWidth.md / 2,
    },
    lg: bodyWidth.lg / 2,
    xl: bodyWidth.xl / 2,
  }
}

export default ({ data }) => {
  const {
    steakPhoto,
    kitchenPhoto,
    lobbyPhoto,
    poolPhoto,
    dessertPhoto,
    snacksImg,
    sunrisePhoto,
    firepitPhoto,
    salesPhoto,
  } = data

  const meta = {
    seo: {
      title: "Hotels in Clemson SC | Lakeside Lodge Clemson",
      img: poolPhoto.localFile.childImageSharp.fluid.src,
      imgAlt: poolPhoto.description,
    },
    uri: "/",
  }

  return (
    <Shell>
      <Meta {...meta} />
      <BookingHero height={`70vh`} />
      {/* Intro */}
      <Row {...fullRow} style={{ margin: `32px auto` }}>
        <Col {...fullCol} style={{ textAlign: `center` }}>
          <Typography.Title level={1} style={{ lineHeight: `48px` }}>
            <span style={{ color: `#4296b4` }}>
              This is Clemson's Only Resort Experience.
            </span>
            <br />
            <span>This is Lakeside Lodge Clemson.</span>
          </Typography.Title>
        </Col>
      </Row>
      {/* The Rooms */}
      <Row {...fullRow} style={{ margin: `72px auto`, maxWidth: 1360 }}>
        <Col {...halfCol}>
          <Img
            alt={kitchenPhoto.description}
            className="shadow-sm"
            fluid={kitchenPhoto.localFile.childImageSharp.fluid}
          />
        </Col>
        <Col {...halfCol}>
          {/* <Typography.Title level={2}>The Rooms</Typography.Title> */}
          <SeoHeader
            seo={{ content: `Hotel`, level: 2 }}
            human={{ content: `The Rooms`, level: 4 }}
          />
          <Typography.Paragraph style={{ margin: `24px auto` }}>
            Rooms at Lakeside Lodge Clemson offer you the comforts of home and
            the simplicity of having everything you need all in one place.
            Whether you choose a suite with a full kitchen or a more traditional
            micro room, you’ll enjoy unique amenities, modern furnishings, and
            conveniences that define comfort.
          </Typography.Paragraph>
          <Button className="norm-btn" size="large" type="primary">
            <Link to="/rooms/">See the Rooms</Link>
          </Button>
        </Col>
      </Row>
      {/* Ultimate Clemson */}
      <div style={{ background: `#F7F7F7` }}>
        <Row
          {...fullRow}
          style={{
            margin: `auto`,
            maxWidth: 1360,
            paddingBottom: 96,
            paddingTop: 72,
            textAlign: `center`,
          }}
        >
          <Col {...fullCol}>
            <Typography.Title
              level={2}
              style={{ fontSize: 48, lineHeight: `44px` }}
            >
              The <span style={{ color: `#FF9600` }}>Ultimate Clemson</span>{" "}
              Experience
            </Typography.Title>
            <Typography.Paragraph
              style={{ marginBottom: 48, textAlign: `center` }}
            >
              Our stunning view of Death Valley across Lake Hartwell, on-site
              farm-to-table restaurant, miniature football field, and numerous
              other amenities separate us from other resorts. We’ve covered
              every luxurious detail because your comfort is our goal.
            </Typography.Paragraph>
            <Row align="middle" type="flex" style={{ marginBottom: `32px` }}>
              <Col style={{ maxHeight: `80vh`, width: `100%` }}>
                <EmbededVideo
                  oEmbedUrl="https://sundoghrv.wistia.com/medias/m55njegy9c?embedType=iframe&videoFoam=true&videoWidth=640"
                  videoDescription="A weekend of fun at Lakeside Lodge Clemson."
                />
              </Col>
            </Row>
            <Row align="middle" gutter={12} type="flex">
              <Col xs={24} md={12} style={{ marginBottom: 12 }}>
                <Img
                  alt={poolPhoto.description}
                  className="shadow-sm"
                  fluid={poolPhoto.localFile.childImageSharp.fluid}
                />
              </Col>
              <Col xs={24} md={12} style={{ marginBottom: 12 }}>
                <Img
                  alt={lobbyPhoto.description}
                  className="shadow-sm"
                  fluid={lobbyPhoto.localFile.childImageSharp.fluid}
                />
              </Col>
            </Row>
            <Row align="middle" gutter={12} type="flex">
              <Col xs={24} md={12} style={{ marginBottom: 12 }}>
                <Img
                  alt={firepitPhoto.description}
                  className="shadow-sm"
                  fluid={firepitPhoto.localFile.childImageSharp.fluid}
                />
              </Col>
              <Col xs={24} md={12} style={{ marginBottom: 12 }}>
                <Img
                  alt={sunrisePhoto.description}
                  className="shadow-sm"
                  fluid={sunrisePhoto.localFile.childImageSharp.fluid}
                />
              </Col>
            </Row>
            <Button
              className="norm-btn"
              size="large"
              type="primary"
              style={{ margin: `32px auto` }}
            >
              <Link to="/amenities/">See Amenities</Link>
            </Button>
          </Col>
        </Row>
      </div>
      {/* The Food */}
      <Row
        {...fullRow}
        style={{
          margin: `auto`,
          maxWidth: 1360,
          paddingBottom: 72,
          paddingTop: 72,
        }}
      >
        <Col {...halfColAlt(2)} style={{ marginBottom: 32, marginTop: -104 }}>
          <Img
            alt={dessertPhoto.description}
            className="shadow-sm"
            fluid={dessertPhoto.localFile.childImageSharp.fluid}
          />
          <div style={{ height: 8 }} />
          <Img
            alt={steakPhoto.description}
            className="shadow-sm"
            fluid={steakPhoto.localFile.childImageSharp.fluid}
          />
        </Col>
        <Col {...halfColAlt(1)}>
          <SeoHeader
            seo={{ content: `Dining`, level: 2 }}
            human={{ content: `The Food`, level: 4 }}
          />
          <Typography.Paragraph>
            At Traditions on the Lake, our chefs prepare delicious meals with
            fresh and familiar ingredients. With an emphasis on cuisine that
            makes America a culinary melting pot, every meal we serve promises
            to be a treat for every palate.
          </Typography.Paragraph>
          <Button
            className="norm-btn"
            size="large"
            type="primary"
            style={{ marginTop: 12 }}
          >
            <Link to="/dining/">Chow Down</Link>
          </Button>
        </Col>
      </Row>
      {/* Events */}
      <div style={{ background: `#F7F7F7` }}>
        <Row
          {...fullRow}
          style={{
            marginBottom: -32,
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: 1360,
            paddingBottom: 72,
            paddingTop: 72,
          }}
        >
          <Col {...halfCol}>
            <Img
              alt={snacksImg.description}
              className="shadow-sm"
              fluid={snacksImg.fluid}
            />
          </Col>
          <Col {...halfCol}>
            <SeoHeader
              seo={{ content: `Events`, level: 2 }}
              human={{ content: `The Fun`, level: 4 }}
            />
            <Typography.Paragraph>
              We don’t take event planning - or fun - lightly. Every reception,
              reunion, meeting, or get-together you plan at Lakeside Lodge
              Clemson is guaranteed to be a memorable one, whether it’s on the
              activity field or in one of our event spaces.
            </Typography.Paragraph>
            <Button
              className="norm-btn"
              size="large"
              type="primary"
              style={{ marginTop: 12 }}
            >
              <Link to="/events/">Book Your Event</Link>
            </Button>
          </Col>
        </Row>
      </div>
      {/* Sales */}
      <Row {...fullRow} style={{ paddingBottom: 72, paddingTop: 72 }}>
        <Col {...halfColAlt(2)}>
          <Img
            alt={salesPhoto.description}
            className="shadow-sm"
            fluid={salesPhoto.localFile.childImageSharp.fluid}
          />
        </Col>
        <Col {...halfColAlt(1)}>
          <SeoHeader
            seo={{ content: `Sales`, level: 2 }}
            human={{ content: `Turn-key Ownership`, level: 4 }}
          />
          <Typography.Paragraph>
            Built by Clemson diehards for Clemson diehards, ownership at
            Lakeside Lodge brings the best of both worlds. Use your condo when
            you can, rent it when you can't, and everything — from linens to
            maintenance — is handled for you (even when you're not here).
          </Typography.Paragraph>
          <Button
            className="norm-btn"
            size="large"
            type="primary"
            style={{ marginTop: 12 }}
          >
            <a href="https://ownership.lakesidelodgeclemson.com">Learn More</a>
          </Button>
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    steakPhoto: contentfulAsset(
      title: { eq: "Traditions - Blackened Scallops" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    kitchenPhoto: contentfulAsset(
      contentful_id: { eq: "19Z2wuN8Nb0EDtHzFieTqO" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    lobbyPhoto: contentfulAsset(
      contentful_id: { eq: "5hksqiIM0KchmaMa0TPk5U" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    poolPhoto: contentfulAsset(
      contentful_id: { eq: "4yvi1KUsiYfYMN9iSTeCcS" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    dessertPhoto: contentfulAsset(
      title: { eq: "Traditions - Seafood Benedict" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    snacksImg: contentfulAsset(title: { eq: "Traditions - Diners" }) {
      description
      fluid {
        ...fluid_withWebp_tracedSVG
      }
    }
    sunrisePhoto: contentfulAsset(
      contentful_id: { eq: "6C1BlRnjsptF68CEfs9vcl" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    firepitPhoto: contentfulAsset(
      contentful_id: { eq: "39jZf5QiuZo59nJfWJvTe9" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 30, webpQuality: 30) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    salesPhoto: contentfulAsset(
      contentful_id: { eq: "2tF2aHMlp3VF3hNOeYc9ih" }
    ) {
      description
      localFile {
        childImageSharp {
          fluid(jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  }
`
