import React from "react"
import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import style from "./WRPSearch.module.scss"

class WRPSearch extends React.Component {
  static propTypes = {
    hotelID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxGuests: PropTypes.number,
    maxOnlineGuests: PropTypes.number,
    dateFormat: PropTypes.string,
  }

  static defaultProps = {
    hotelID: process.env.WRP_HOTEL_ID,
    maxGuests: 8,
    maxOnlineGuests: 4,
    dateFormat: `YYYYMMDD`,
  }

  constructor(props) {
    super(props)
    this.state = {
      dateFrom: null,
      dateTo: null,
      numAdults: null,
      buttonDisabled: true,
      existingParams: "",
      modalVis: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        existingParams: window.location.search.substring(1),
      })
    }
  }

  componentDidUpdate() {
    if (
      this.state.dateFrom !== null &&
      this.state.dateTo !== null &&
      this.state.numAdults !== null
    ) {
      this.enableButton()
    } else {
      this.disableButton()
    }
  }

  enableButton = _ => {
    if (
      this.state.buttonDisabled &&
      this.state.numAdults <= this.props.maxOnlineGuests
    ) {
      this.setState({
        buttonDisabled: false,
      })
    }
  }

  disableButton = _ => {
    if (!this.state.buttonDisabled) {
      this.setState({
        buttonDisabled: true,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    let params = `?nextcommand=roomsearch`
    params += `&date_from=${this.state.dateFrom}`
    params += `&date_to=${this.state.dateTo}`
    params += `&num_adults=${this.state.numAdults}`

    if (typeof document !== "undefined") {
      let existingParams = window.location.search.substring(1)
      let url = `${process.env.BOOKING_ENGINE}${params}&${existingParams}`
      process.env.NODE_ENV === "development"
        ? console.log(url)
        : (document.location = url)
    }
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    })
  }

  checkInChange = value => {
    const { dateTo } = this.state
    this.setState({
      dateFrom: value !== null ? value.format(this.props.dateFormat) : null,
      dateTo:
        value !== null && dateTo !== null && value.isAfter(moment(dateTo))
          ? null
          : dateTo,
    })
  }

  checkOutChange = value => {
    const { dateFrom } = this.state
    this.setState({
      dateTo: value !== null ? value.format(this.props.dateFormat) : null,
      dateFrom:
        value !== null && dateFrom !== null && value.isBefore(moment(dateFrom))
          ? null
          : dateFrom,
    })
  }

  disabledCheckInDates = current => {
    return current && current < moment().startOf("day")
  }

  disabledCheckOutDates = endDate => {
    return !endDate || !moment(this.state.dateFrom)
      ? false
      : endDate.valueOf() <= moment(this.state.dateFrom)
  }

  calendarRenderer = current => {
    const checkIn =
      current.format(this.props.dateFormat) === this.state.dateFrom
    const checkOut = current.format(this.props.dateFormat) === this.state.dateTo
    const between = current.isBetween(
      moment(this.state.dateFrom),
      moment(this.state.dateTo)
    )

    if (checkIn || checkOut) {
      const style = {
        background: `#4296b4`,
        borderBottomLeftRadius: checkIn ? `100%` : 0,
        borderBottomRightRadius: checkIn ? 0 : `100%`,
        borderTopLeftRadius: checkIn ? `100%` : 0,
        borderTopRightRadius: checkIn ? 0 : `100%`,
        color: `#fff`,
        height: `100%`,
        width: `75%`,
      }
      return (
        <Tooltip title={checkIn ? `Check-in` : `Check-out`}>
          <div className="ant-calendar-date" style={style}>
            {current.date()}
          </div>
        </Tooltip>
      )
    } else if (between) {
      const style = {
        background: `rgba(66, 150, 180, .1)`,
      }
      return (
        <div className="ant-calendar-date" style={style}>
          {current.date()}
        </div>
      )
    }
    return <div className="ant-calendar-date">{current.date()}</div>
  }

  numGuestsChange = value => {
    this.setState({
      numAdults: value,
    })
  }

  guestsOptions(num) {
    let options = []
    for (let i = 0; i < num; i++) {
      options.push(i + 1)
    }
    return options.map(option => (
      <Select.Option
        key={`guests-${option}`}
        onClick={() => {
          if (option > this.props.maxOnlineGuests) {
            this.setState({ buttonDisabled: true, modalVis: true })
          } else {
            const { dateFrom, dateTo, buttonDisabled } = this.state
            if (dateFrom && dateTo && buttonDisabled) {
              this.enableButton()
            }
          }
        }}
        id={
          option > this.props.maxOnlineGuests
            ? `call-to-book-${option}`
            : `book-online-${option}`
        }
        value={option}
      >
        {option}
      </Select.Option>
    ))
  }

  render() {
    return (
      <>
        <Modal
          centered
          closable={false}
          footer={null}
          visible={this.state.modalVis}
        >
          <Typography.Title level={3}>Sounds like fun!</Typography.Title>
          <Typography.Paragraph>
            We can't wait to host your group — but we can only accept
            reservations for up to 4 guests per room online.{" "}
            <strong>
              Please call if you'd like to reserve a two- or three-bedroom condo
              for a party of 5 to 8 guests.
            </strong>{" "}
            Alternatively, you can book multiple rooms for up to 4 guests each
            online. Thank you!
          </Typography.Paragraph>
          <Typography.Title
            level={4}
            style={{ marginBottom: 36, textAlign: `center` }}
          >
            {this.props.phone}
          </Typography.Title>
          <Row align="middle" gutter={24} justify="center" type="flex">
            <Col span={12}>
              <Button
                size="large"
                onClick={() => this.setState({ modalVis: false })}
                style={{ width: `100%` }}
              >
                <Icon type="close" /> Close
              </Button>
            </Col>
            <Col span={12}>
              <Button size="large" type="primary" style={{ width: `100%` }}>
                <a href={`tel:${this.props.phone}`}>
                  <Icon type="phone" /> Call Now!
                </a>
              </Button>
            </Col>
          </Row>
        </Modal>
        <Form className={style.form} onSubmit={this.handleSubmit}>
          <Row align="middle" gutter={[0, 12]} justify="center" type="flex">
            <Col xs={10} md={5}>
              <DatePicker
                className={`
                ${style.checkIn}
                ${this.state.dateFrom === null ? style.empty : style.filled}
              `}
                dateRender={this.calendarRenderer}
                disabledDate={this.disabledCheckInDates}
                format={`ddd, MMM Do`}
                id="check-in-date"
                onChange={this.checkInChange}
                placeholder="Check-in"
                showToday={false}
                size="large"
                suffixIcon={<Icon type="calendar" className={style.icon} />}
                value={
                  moment(this.state.dateFrom).isValid()
                    ? moment(this.state.dateFrom)
                    : null
                }
              />
            </Col>
            <Col xs={4} md={2}>
              <Input
                className={style.separator}
                disabled
                label="Unused field"
                value={` to `}
                size="large"
              />
            </Col>
            <Col xs={10} md={5}>
              <DatePicker
                className={`
                ${style.checkOut}
                ${this.state.dateTo === null ? style.empty : style.filled}
              `}
                dateRender={this.calendarRenderer}
                // disabled={this.state.dateFrom === null}
                disabledDate={this.disabledCheckOutDates}
                format={`ddd, MMM Do`}
                id="check-out-date"
                onChange={this.checkOutChange}
                placeholder="Check-out"
                showToday={false}
                size="large"
                value={
                  moment(this.state.dateTo).isValid()
                    ? moment(this.state.dateTo)
                    : null
                }
              />
            </Col>
            <Col xs={4} md={2}>
              <Select
                className={`
                ${style.numGuests}
                ${
                  this.state.numAdults === null
                    ? style.untouched
                    : style.touched
                }
              `}
                defaultActiveFirstOption={false}
                onChange={this.numGuestsChange}
                placeholder={`0`}
                size="large"
              >
                {this.guestsOptions(this.props.maxGuests)}
              </Select>
            </Col>
            <Col xs={5} md={3}>
              <Input
                className={style.guests}
                disabled
                label="Unused field"
                value={` Guest${this.state.numAdults !== 1 ? "s" : ""}`}
                size="large"
              />
            </Col>
            <Col xs={15} md={7}>
              <Button
                block
                className={style.btn}
                disabled={this.state.buttonDisabled}
                // htmlType="submit"
                size="large"
                type="primary"
              >
                <a
                  href={`${process.env.BOOKING_ENGINE}?nextcommand=roomsearch&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&num_adults=${this.state.numAdults}&${this.state.existingParams}`}
                >
                  {this.state.buttonDisabled ? `Select Dates` : `Book Now!`}
                </a>
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}

const WRPSearchForm = Form.create({ name: "wrp_search_form" })(WRPSearch)

export default WRPSearchForm
